import React from 'react'
import {graphql, StaticQuery} from "gatsby";
import {URL} from "../../constants/urls";
import {STRINGS} from "../../constants/strings";
import { FooterAMP } from '@cg-squad/ui-components';
import { FOLLOW_US } from '../../constants/followUs';

const renderNavItems = (props) => (
    <StaticQuery
        query={graphql`
            query FooterCategoryQueryAMP {
              allCategories: allDatoCmsCategory(
                filter: {root: {eq: true}, slug: {ne: "news"}, website: {elemMatch: {name: {eq: "at"}}}}
                sort: {order: ASC, fields: position}
              ) {
                nodes {
                  slug
                  title
                }
              }
            }
        `}
        render={data => 
          <FooterAMP categoryPath={props.categoryPath} subCategoryPath={props.subCategoryPath}
                              allCategories={data.allCategories} stringsConst={STRINGS} urlsConst={URL} 
                              followUs={FOLLOW_US}/>
        }
    />
)

const FooterAMPWrapper = (props) => {

    return (
      <>{renderNavItems(true, props)}</>
    )
}

export default FooterAMPWrapper
