import React from 'react';

const AdHeaderAMP = () => {
    return <></>;/*
    return <amp-ad width="320" height="100"
                   type="doubleclick"
                   data-slot="/22218985137/agetimes/at_leaderboard_atf_mobile"
                   data-multi-size="320x50"
                   data-multi-size-validation="false">
    </amp-ad>*/
}

export default AdHeaderAMP;
