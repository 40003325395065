import React from 'react'
import cx from "classnames";
import getYouTubeID from "get-youtube-id";

const EmbedYoutubeAmp = (props) => {
    const className = cx(
        "youtube-videoWrapper",
        props.className
    );

    const videoId = props.videoId ? props.videoId : getYouTubeID(props.video);

    return <div className={className} style={props.style}>
        <iframe width="560" height="315" src={`https://www.youtube.com/embed/${videoId}`} title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen/>
    </div>
}

export default EmbedYoutubeAmp;